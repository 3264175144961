<template>
  <div class="container" v-if="initOk">
    <van-notice-bar
      v-if="id && status == 'PENDING'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="请等待教务处审核"
    />

    <van-notice-bar
      v-if="id && status == 'SUCCEED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="您的申请已通过审核"
    />

    <van-notice-bar
      v-if="id && status == 'FAILED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      :text="'您的申请未通过审核，有任何疑问请联系我们！' + approve"
    />

    <van-form @submit="onSubmit">
      <p class="input-info">预估到校时间</p>
      <van-field
        readonly
        clickable
        :disabled="id == undefined ? false : true"
        name="planCheckinTimeStr"
        :value="planCheckinTimeStr"
        placeholder="请选择"
        @click="showPlanCheckinTimePicker = true"
        :rules="[{ required: true, message: '请选择预估到校时间' }]"
      />
      <van-popup
        v-if="id == undefined"
        v-model="showPlanCheckinTimePicker"
        position="bottom"
      >
        <van-datetime-picker
          type="datehour"
          :min-date="new Date()"
          :min-hour="8"
          :max-hour="22"
          :formatter="formatter"
          @confirm="onPlanCheckinTimeConfirm"
          @cancel="showPlanCheckinTimePicker = false"
        />
      </van-popup>
      <p class="input-info">证明材料</p>
      <van-field
        name="uploader"
        :rules="[{ required: true, message: '请选择照片' }]"
      >
        <template #input>
          <van-uploader
            :disabled="id == undefined ? false : true"
            :deletable="id == undefined ? true : false"
            v-model="uploader"
            :after-read="afterRead"
            multiple
            :max-count="3"
            :max-size="10 * 1024 * 1024"
            @oversize="onOversize"
          />
        </template>
      </van-field>
      <p class="input-info">申请原因</p>
      <van-field
        :disabled="id == undefined ? false : true"
        v-model="reason"
        name="reason"
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入申请原因' }]"
      />
      <div v-if="id == undefined" style="margin: 16px">
        <van-button round block type="danger" native-type="submit"
          >提交</van-button
        >
      </div>
      <div v-else-if="id && status != 'SUCCEED'" style="margin: 16px">
        <van-button
          round
          block
          type="default"
          native-type="button"
          @click="cancel()"
          >取消申请</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Notify } from "vant";
import { Dialog } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      id: undefined,
      status: undefined,
      planCheckinTime: undefined,
      planCheckinTimeStr: undefined,
      showPlanCheckinTimePicker: false,
      reason: undefined,
      uploader: [],
    };
  },
  created() {
    this.findDelayReq();
  },
  methods: {
    onPlanCheckinTimeConfirm(time) {
      this.planCheckinTime = this.dateFormat("YYYY-mm-dd H:00:00", time);
      this.planCheckinTimeStr = this.dateFormat("YYYY年mm月dd日,H时", time);
      this.showPlanCheckinTimePicker = false;
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "hour") {
        return `${val}时`;
      }
      return val;
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    //上传
    onOversize(file) {
      console.log(file);
      Toast.fail("文件大小不能超过 10MB");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("file", file.file);
      api.commonUpload(formData).then((res) => {
        if (res.state == "ok") {
          file.status = "succeed";
          file.message = "上传成功";
          file.url = res.msg;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //上传END
    findDelayReq() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.delayReq().then((res) => {
        if (res.id) {
          this.id = res.id;
          this.status = res.status;
          this.planCheckinTime = res.planCheckinTime;
          this.planCheckinTimeStr = this.dateFormat(
            "YYYY年mm月dd日,H时",
            new Date(res.planCheckinTime.replaceAll("-", "/"))
          );
          this.uploader = [];
          if (res.pics) {
            JSON.parse(res.pics).forEach((r) => {
              this.uploader.push({ url: r });
            });
          }
          this.reason = res.reason;
        } else {
          this.id = undefined;
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onSubmit(values) {
      values.planCheckinTime = this.planCheckinTime;
      let pics = [];
      this.uploader.forEach((u) => {
        pics.push(u.url);
      });
      values.uploader = [];
      values.pics = JSON.stringify(pics);
      api.insertDelayReq(values).then((res) => {
        if (res.state == "ok") {
          // this.findDelayReq();
          Toast.success("提交成功,请等待审核");
          this.$router.push({ path: "/me", query: {} });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
      console.log("submit", values);
    },
    cancel() {
      Dialog.confirm({
        title: "提示",
        message: "您确认取消吗？",
      })
        .then(() => {
          api.cancelDelayReq().then((res) => {
            if (res.state == "ok") {
              this.findDelayReq();
              Toast.success("成功取消");
            } else {
              Notify({ type: "danger", message: res.msg });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
.input-info {
  padding: 10px;
  margin: 0;
  font-size: 14px;
}
.van-checkbox {
  margin-bottom: 10px;
  font-size: 14px;
}
.van-checkbox:last-child {
  margin-bottom: 0px;
}
</style>